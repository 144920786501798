import { Component } from '@angular/core';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { UtilService } from '../../services/util.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrl: './user-modal.component.scss'
})
export class UserModalComponent {

  public dataForm: DataFormModel = {
    name: {
      value: '',
      type: 'text',
      label: 'Nombre',
      requiredMessage: 'Por favor, ingrese el nombre del usuario',
      required: true,
      validators: [Validators.required]
    },
    paternalSurname: { 
      value: '',
      type: 'text',
      label: 'Apellido paterno',
      requiredMessage: 'Por favor, rellene este campo',
      required: true,
      validators: [Validators.required]
    },
    maternalSurname: { 
      value: '',
      type: 'text',
      label: 'Apellido materno',
      required: true,
      validators: [Validators.required]
    },
    email: { 
      value: '',
      type: 'email',
      label: 'Correo electrónico',
      required: true,
      validators: [Validators.required]
    },
    password: { 
      value: '',
      type: 'password',
      label: 'Contraseña',
      required: true,
      validators: [Validators.required]
    },
  };

  public submitted: boolean = false;
  public loading: boolean = false;

  public formUser!: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public authService: AuthService,
    public profileService: ProfileService,
    public userService: UserService,
    public utilService: UtilService,
  ) {
    this.loadData();
  }

  get f() {
    return this.formUser.controls;
  }

  /*
   ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗██╗   ██╗██╗███████╗██╗██████╗ ██╗██╗     ██╗████████╗██╗   ██╗██████╗  █████╗ ███████╗███████╗
  ██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝██║   ██║██║██╔════╝██║██╔══██╗██║██║     ██║╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔══██╗██╔════╝██╔════╝
  ██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗  ██║   ██║██║███████╗██║██████╔╝██║██║     ██║   ██║    ╚████╔╝ ██████╔╝███████║███████╗███████╗
  ██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝  ╚██╗ ██╔╝██║╚════██║██║██╔══██╗██║██║     ██║   ██║     ╚██╔╝  ██╔═══╝ ██╔══██║╚════██║╚════██║
  ╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗ ╚████╔╝ ██║███████║██║██████╔╝██║███████╗██║   ██║      ██║   ██║     ██║  ██║███████║███████║
   ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝  ╚═══╝  ╚═╝╚══════╝╚═╝╚═════╝ ╚═╝╚══════╝╚═╝   ╚═╝      ╚═╝   ╚═╝     ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  /**
   * FUNCTION changeVisibilityPass
   */
  changeVisibilityPass() {
    let visibility = this.dataForm['password'].type === 'text';

    if(visibility) {
      this.dataForm['password'].type = 'password';
    } else {
      this.dataForm['password'].type = 'text';
    }
  }

  /*
   ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗  █████╗ ███████╗███████╗██╗    ██╗ ██████╗ ██████╗ ██████╗
  ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝██║    ██║██╔═══██╗██╔══██╗██╔══██╗
  ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██████╔╝███████║███████╗███████╗██║ █╗ ██║██║   ██║██████╔╝██║  ██║
  ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██╔═══╝ ██╔══██║╚════██║╚════██║██║███╗██║██║   ██║██╔══██╗██║  ██║
  ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██║     ██║  ██║███████║███████║╚███╔███╔╝╚██████╔╝██║  ██║██████╔╝
   ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝     ╚═╝  ╚═╝╚══════╝╚══════╝ ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═════╝

  */
  /**
  * FUNCTION generatePassword
  */
  generatePassword() {
    this.formUser.controls['password'].setValue(
      this.utilService.generateRandomString(10)
    )
  }


  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
 /**
  * FUNCTION loadData
  */
  async loadData() {
    this.loading = true;

    this.formUser = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
 /**
  * FUNCTION onSubmit
  * 
  * @returns 
  */
  async onSubmit() {

    console.log(this.formUser.value);
    

    this.submitted = true;

    if(this.formUser.invalid) {
      return;
    }

    const flag = await this.authService.signUp(
      this.formUser.value
    );

    if(flag) {
      this.userService.getAll();
      this.activeModal.close();
    }
  }

}
