<div class="modal-header">
  <h4 class="modal-title">
    Registrar usuario
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  @if (!loading) {
    <form
      id="formUser"
      [formGroup]="formUser"
      (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <div class="row">

          @for (key of utilService.objectKeys(dataForm); track key; let index = $index) {
            @if (dataForm[key].type != "select") {
              <div class="col-12 form-group mb-4">
                <label [htmlFor]="key" class="form-label">
                  {{ dataForm[key].label }}
                </label>
                <input
                  [type]="dataForm[key].type"
                  class="form-control"
                  [placeholder]="dataForm[key].placeholder ?? ''"
                  formControlName="{{ key }}"
                  (blur)="dataForm[key].onBlur ? dataForm[key].onBlur!() : null"
                  [ngClass]="{
                    'is-invalid': submitted && f[key].errors
                  }">
                  @if (submitted && f[key].errors && dataForm[key].required) {
                    <div class="invalid-feedback">
                      @if (f[key].errors!['required']) {
                        <div>{{ dataForm[key].requiredMessage }}</div>
                      }
                    </div>
                  }
              </div>
            }

            @if (dataForm[key].type === 'select') {
              <div class="mb-4">
                <div class="mb-1">
                  {{ dataForm[key].label }}
                </div>
                <ng-select
                  [items]="dataForm[key].data!"
                  [bindLabel]="dataForm[key].labelSelect!"
                  [bindValue]="dataForm[key].idSelect!"
                  [placeholder]="dataForm[key].placeholder ?? ''"
                  (ngModelChange)="dataForm[key].onChange ? dataForm[key].onChange!($event) : null"
                  formControlName="{{ key }}">
  
                </ng-select>
                @if (submitted && f[key].errors && dataForm[key].required) {
                  <div class="text-danger">
                    @if (f[key].errors!['required']) {
                      {{ dataForm[key].requiredMessage }}
                    }
                  </div>
                }
              </div>
            }
          }

          <div class="col-12 mb-2">
            <button
              (click)="generatePassword()"
              type="button"
              class="btn btn-success w-100">
              Generar contraseña
            </button>
          </div>

          <div class="col-12 mb-4">
            <button
              (click)="changeVisibilityPass()"
              type="button"
              class="btn w-100">
              {{ this.dataForm['password'].type === 'password' ? 'Mostrar contraseña' : 'Ocultar contraseña' }}
            </button>
          </div>

        </div>
      </div>
    </form>
  }
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formUser"
      class="btn btn-primary w-100">
      Registrar usuario
    </button>
  </div>
</div>