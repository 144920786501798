export interface IBranch {
  idBranch: string;
  idCompany: string;
  uidOwner: string;
  name: string;
  lineAddress1: string;
  lineAddress2: string;
  neighborhood: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  createdAt: string;
  updatedAt: string;
}

export class Branch {
  idBranch: string;
  idCompany: string;
  uidOwner: string;
  name: string;
  lineAddress1: string;
  lineAddress2: string;
  neighborhood: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IBranch) {
    this.idBranch = data.idBranch;
    this.idCompany = data.idCompany;
    this.uidOwner = data.uidOwner;
    this.name = data.name;
    this.lineAddress1 = data.lineAddress1;
    this.lineAddress2 = data.lineAddress2;
    this.neighborhood = data.neighborhood;
    this.city = data.city;
    this.state = data.state;
    this.zipCode = data.zipCode;
    // Convertir los strings de latitud y longitud a números
    this.latitude = parseFloat(data.latitude);
    this.longitude = parseFloat(data.longitude);
    // Convertir las cadenas de fecha a objetos Date
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }

  getFullAddress(): string {
    return `${this.neighborhood}, ${this.city}, ${this.state}, ${this.zipCode}`;
  }
}
