import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { IProfile, Profile } from '../models/profile.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public profiles: Profile[] = [];
  public loading: boolean = false;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 
    this.getAll();
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  /**
   * FUNCTION getAll
   */
  async getAll() {
    this.loading = true;

    const urlApi = `${environment.urlAPI}/profiles`;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ profiles: IProfile[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ profiles: IProfile[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ profiles: IProfile[] }>
              ) => resolve(
                new ServerResponse<{ profiles: IProfile[] }>(response)
              ),
            error: (error) => {

              this.toastService.show(
                {
                  header: 'Error de servidor',
                  body: `${error}`
                }
              )

              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`
                  }
                );
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        this.profiles = response.data!.profiles.map(
          (profile) => new Profile(profile)
        );
      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`
        }
      );
    }

    this.loading = false;
  }
}
