export interface IProfile {
  updatedAt: string;
  profile: string;
  createdAt: string;
}

export class Profile {
  updatedAt: Date;
  profile: string;
  createdAt: Date;

  constructor(data: IProfile) {
    this.updatedAt = new Date(data.updatedAt);
    this.profile = data.profile;
    this.createdAt = new Date(data.createdAt);
  }
}
